import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Message = translated('app.project')(
  'RequestList.Flow.Steps.CompleteStep.Message',
)

/**
 */
export const CompleteButton = translated('app.project')(
  'RequestList.Flow.Steps.CompleteStep.CompleteButton',
)
