import React from 'react'

import * as ProjectDestination from '@arch-log/webapp.modules/project.destination.v2/components'
import * as ProjectDestinationEntry from '@arch-log/webapp.modules/project.destination.v2/components/Scope/Entry'
import * as SampleRequest from '@arch-log/webapp.modules/project.sample_request/components/Reference'
import * as Address from './Address'
import * as DefaultComponents from './DefaultComponents'
import * as Literals from './Literals'
import * as styles from './styles.module.scss'

/**
 */
export const Shipments = ({
  components: { Container = DefaultComponents.ShipmentsContainer } = {},
}) => {
  return (
    <Container>
      <ProjectDestination.Scope.Entries.Each>
        <Shipment />
      </ProjectDestination.Scope.Entries.Each>
    </Container>
  )
}

/**
 */
export const Shipment = ({
  components: { Container = DefaultComponents.ShipmentContainer } = {},
}) => {
  const [entry] = ProjectDestinationEntry.useEntry()

  return (
    <SampleRequest.Shipment.Initializer address={entry.id}>
      <SampleRequest.Shipment.Actions.Initializer>
        <Container>
          <ShipmentCheckBox />
          <ShipmentAddress />
          <ShipmentQuantity />
        </Container>
      </SampleRequest.Shipment.Actions.Initializer>
    </SampleRequest.Shipment.Initializer>
  )
}

/**
 */
const ShipmentCheckBox = ({
  components: { CheckBox = DefaultComponents.CheckBox } = {},
}) => {
  const {
    shipment: { isActive, quantity = 1 },
  } = React.useContext(SampleRequest.Shipment.Context)

  const { updateEntryShipment } = React.useContext(
    SampleRequest.Shipment.Actions.Context,
  )

  return (
    <CheckBox
      value={isActive}
      onChangeValue={() => updateEntryShipment(!isActive, quantity)}
    />
  )
}

/**
 */
const ShipmentAddress = ({
  components: {
    Container = DefaultComponents.ShipmentAddressContainer,
    Item = DefaultComponents.ShipmentAddresItemContainer,
  } = {},
}) => {
  const {
    shipment: { isActive, quantity = 1 },
  } = React.useContext(SampleRequest.Shipment.Context)

  const { updateEntryShipment } = React.useContext(
    SampleRequest.Shipment.Actions.Context,
  )
  return (
    <Container onClick={() => updateEntryShipment(!isActive, quantity)}>
      <Item>
        <Address.CompanyName />
        <Address.OperatorName />
      </Item>
      <Item>
        <Address.Full />
      </Item>
    </Container>
  )
}

/**
 */
const ShipmentQuantity = ({
  components: {
    Container = DefaultComponents.ShipmentQuantityContainer,
    Select = DefaultComponents.SelectContainer,
  } = {},
}) => {
  const {
    shipment: { isActive, quantity },
  } = React.useContext(SampleRequest.Shipment.Context)

  const { updateEntryShipment } = React.useContext(
    SampleRequest.Shipment.Actions.Context,
  )

  return (
    <Container>
      <Select
        value={isActive}
        disabled={!isActive}
        defaultValue={quantity}
        onChange={(e) => {
          updateEntryShipment(true, Number(e.target.value))
        }}
      >
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
      </Select>
    </Container>
  )
}

/**
 */
export const Note = () => {
  const {
    sampleRequest: { remarks, shipments },
  } = React.useContext(SampleRequest.Context)

  const { updateEntryMeta } = React.useContext(SampleRequest.Actions.Context)
  const { formatPlaceholder } = Literals.useLiterals()

  const isActiveShipments = shipments.some(
    (shipment) => shipment.isActive === true,
  )

  return (
    <textarea
      disabled={!isActiveShipments}
      className={styles.TextArea}
      placeholder={formatPlaceholder('Note')}
      defaultValue={remarks}
      onBlur={(e) => {
        updateEntryMeta({ remarks: e.target.value })
      }}
    ></textarea>
  )
}
