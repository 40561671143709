import React from 'react'

//
import * as SampleRequests from '@arch-log/webapp.modules/project.sample_request/components'
import * as ProjectDestination from '@arch-log/webapp.modules/project.destination.v2/components'
import * as Errors from '@arch-log/webapp.shared/Project.v2/Errors'

import * as Containers from './Containers'
import * as Sort from './Sort'
import * as Layout from './Layout'

/**
 */
export const Default = ({
  components: {
    Layout: {
      Wrapper = Layout.Wrapper,
      Body = Layout.Body,
      Header = Layout.Header,
      BodyHeader = Layout.BodyHeader,
      BodyHeaderLeft = Layout.BodyHeaderLeft,
      BodyHeaderRight = Layout.BodyHeaderRight,
      Footer = Layout.Footer,
    } = {},
  } = {},
  next,
}) => {
  const [total] = ProjectDestination.Remote.Queries.Entries.useTotal()

  if (total === 0) {
    return <Errors.ShippingAddressNotExists.Default />
  }

  return (
    <SampleRequests.AllLoader>
      <SampleRequests.Filter
        byNotManufacturer={['Demo Products', 'Accessories']}
      >
        <Sort.Initializer>
          <Wrapper>
            <Header>
              <Containers.Title />
            </Header>
            <Body>
              <BodyHeader>
                <BodyHeaderLeft>
                  <Containers.Sorter />
                </BodyHeaderLeft>
                <BodyHeaderRight>
                  <Containers.LinkToHistory />
                </BodyHeaderRight>
              </BodyHeader>
              <Containers.Entries>
                <Containers.Entry />
              </Containers.Entries>
            </Body>
            <Footer>
              <Containers.Submit onSuccess={() => next()} />
            </Footer>
          </Wrapper>
        </Sort.Initializer>
      </SampleRequests.Filter>
    </SampleRequests.AllLoader>
  )
}
