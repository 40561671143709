import React from 'react'

import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'

export const LinkToProduct = translated('app.project')(
  'RequestList.Entry.ToProductDetail',
)

export const useLiterals = () => {
  const { t } = useTranslation(['app', 'app.project'])

  const formatPlaceholder = (key, ...options) =>
    t(`app:RequestList.Placeholders.${key}`, ...options)

  return {
    formatPlaceholder,
  }
}
