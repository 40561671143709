import React from 'react'

import * as Icons from '@arch-log/webapp.shared/Icons'
import * as styles from './styles.module.scss'

/**
 */
export const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 */
export const ImageWrapper = ({ children }) => (
  <div className={styles.ImageWrapper}>{children}</div>
)

/**
 */
export const TitleAndFormWrapper = ({ children }) => (
  <div className={styles.TitleAndFormWrapper}>{children}</div>
)

/**
 */
export const TitleSectionContainer = ({ children }) => (
  <div className={styles.TitleSectionContainer}>{children}</div>
)

/**
 */
export const LinkSectionContainer = ({ children }) => (
  <div className={styles.LinkSectionContainer}>{children}</div>
)

/**
 */
export const LinkSectionIconContainer = ({ children }) => (
  <div className={styles.LinkSectionIconContainer}>{children}</div>
)
/**
 */
export const FormSectionContainer = ({ children }) => <>{children}</>

/**
 */
export const ProductNameContainer = ({ children }) => (
  <div className={styles.ProductNameContainer}>{children}</div>
)

/**
 */
export const ManufacturerContainer = ({ children }) => (
  <div className={styles.ManufacturerContainer}>{children}</div>
)

/**
 */
export const DeleteButton = ({ children, onDelete }) => (
  <div
    className={styles.DeleteButton}
    onClick={() => {
      onDelete()
    }}
  >
    {children}
  </div>
)

export const AddressComponentContainer = ({ children }) => (
  <span className={styles.AddressComponent}>{children}</span>
)

export const ShipmentsContainer = ({ children }) => (
  <div className={styles.ShipmentsContainer}>{children}</div>
)

export const ShipmentContainer = ({ children }) => (
  <div className={styles.ShipmentContainer}>{children}</div>
)

export const ShipmentAddressContainer = ({ children, onClick }) => (
  <label className={styles.Address} onClick={onClick}>
    <div className={styles.ShipmentAddressContainer}>{children}</div>
  </label>
)

export const ShipmentAddresItemContainer = ({ children }) => (
  <div className={styles.ShipmentAddresItemContainer}>{children}</div>
)

export const ShipmentQuantityContainer = ({ children }) => (
  <div className={styles.ShipmentQuantityContainer}>{children}</div>
)

export const CheckBox = ({ value, onChangeValue }) => {
  return (
    <span onClick={() => onChangeValue()} className={styles.CheckBox}>
      <CheckBoxIcon value={value} />
    </span>
  )
}

export const CheckBoxIcon = ({ value }) => {
  if (value) {
    return <Icons.CheckBox_Checked_Fill />
  } else {
    return <Icons.CheckBox_Unchecked />
  }
}

/**
 */
export const SelectContainer = ({ value, children, ...props }) => {
  if (value) {
    return (
      <select className={styles.SelectContainer} {...props}>
        {children}
      </select>
    )
  } else {
    return (
      <select className={styles.SelectContainer_Deactive} {...props}>
        {children}
      </select>
    )
  }
}
