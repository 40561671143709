import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)
/**
 */
export const EntriesContainer = ({ children }) => (
  <div className={styles.Entries_Container}>{children}</div>
)

/**
 */
export const EntryContainer = ({ children }) => (
  <div className={styles.Entry_Container}>{children}</div>
)

/**
 */
export const Title = ({ children }) => (
  <span className={styles.Title}> {children}</span>
)

/**
 */
export const SubmitButton = ({ value, children, ...props }) => {
  if (value) {
    return (
      <button className={styles.SubmitButton} {...props}>
        {children}
      </button>
    )
  } else {
    return (
      <div className={styles.SubmitButton_Deactive} {...props}>
        {children}
      </div>
    )
  }
}

/**
 */
export const LinkToHistoryContainer = ({ children }) => (
  <div className={styles.LinkToHistoryContainer}> {children}</div>
)
