import React from 'react'

import * as styles from './layout.module.scss'

/**
 */
export const Wrapper = ({ children }) => (
  <div className={styles.Wrapper}>{children}</div>
)

export const Body = ({ children }) => (
  <div className={styles.Body}>{children}</div>
)

/**
 */
export const Header = ({ children }) => (
  <div className={styles.Header}>{children}</div>
)

/**
 */
export const BodyHeader = ({ children }) => (
  <div className={styles.Layout_BodyHeader}>{children}</div>
)

/**
 */
export const BodyHeaderLeft = ({ children }) => (
  <div className={styles.Layout_BodyHeaderLeft}>{children}</div>
)

/**
 */
export const BodyHeaderRight = ({ children }) => (
  <div className={styles.Layout_BodyHeaderRight}>{children}</div>
)

/**
 */
export const Footer = ({ children }) => (
  <div className={styles.Footer}>{children}</div>
)
