import React from 'react'

import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Title = translated('app.project')('RequestList.Title')

/**
 */
export const Submit = translated('app.project')('RequestList.Send')

/**
 */
export const LinkToHistory = translated('app.project')('RequestList.ToHistory')

export const useEmbedded = () => {
  const { t } = useTranslation('app')

  const formatSortKey = (sortKey) => {
    return t(`RequestList.Sort.Keys.${sortKey}`)
  }

  return {
    formatSortKey,
  }
}
