import React from 'react'

import { Default as EntryContent } from '../Entry'

import {
  OrderBy,
  SortKeys,
  Direction,
} from '@arch-log/webapp.modules/project.sample_request/order'

import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as SampleRequests from '@arch-log/webapp.modules/project.sample_request/components'
import * as Languages from '@arch-log/webapp.modules/i18n/components/Languages'
import * as ProjectDestination from '@arch-log/webapp.modules/project.destination.v2/components'
import * as ProjectLinks from '@arch-log/webapp.shared/Project.v2/Links'
import * as BaseSorter from '@arch-log/webapp.shared/Sorter'
import * as Sort from './Sort'
import * as Literals from './Literals'
import * as Styled from './Styled'

export const Sorter = () => {
  const { setOrderCondition } = React.useContext(Sort.Context)

  const { formatSortKey } = Literals.useEmbedded()

  return (
    <BaseSorter.Default
      defaultValue={OrderBy.RegisteredAt}
      sortKeys={SortKeys}
      formatSortKey={(v) => formatSortKey(v)}
      onChangeValue={(key, direction) => {
        setOrderCondition({ sortKey: key, direction })
      }}
    />
  )
}

/**
 */
export const Entry = ({
  components: {
    Container = Styled.EntryContainer,
    Content: contentComponents,
  } = {},
}) => (
  <Container>
    <EntryContent components={contentComponents} />
  </Container>
)

/**
 */
export const Entries = ({
  children,
  components: { Container = Styled.EntriesContainer } = {},
}) => {
  const {
    orderCondition: { sortKey, direction },
  } = React.useContext(Sort.Context)

  const { language } = React.useContext(Languages.Context)

  return (
    <Container>
      <SampleRequests.Sort
        orderBy={sortKey}
        direction={direction}
        language={language}
      >
        <SampleRequests.Each>{children}</SampleRequests.Each>
      </SampleRequests.Sort>
    </Container>
  )
}

/**
 */
export const Title = ({ components: { Title = Styled.Title } = {} }) => (
  <Title>
    <Literals.Title />
  </Title>
)

export const Submit = ({
  components: { Button = Styled.SubmitButton } = {},
  onSuccess,
}) => {
  const { entries } = React.useContext(SampleRequests.Context)
  const { sendEntries } = React.useContext(SampleRequests.Actions.Context)

  const isActive =
    entries
      .map((request) =>
        request.shipments.filter((shipment) => shipment.isActive),
      )
      .flat().length > 0

  return (
    <Button
      value={isActive}
      type="button"
      onClick={() => {
        sendEntries({
          options: {
            onSuccess: () => {
              onSuccess()
            },
          },
        })
      }}
    >
      <Literals.Submit />
    </Button>
  )
}

export const Initializer = ({ children }) => {
  const [projectEntry] = Project.Scope.Entry.useEntry()

  return (
    <SampleRequests.Initializer project={projectEntry.id}>
      <SampleRequests.Actions.Initializer>
        <ProjectDestination.Remote.Queries.Entries.Loader
          projectId={projectEntry.id}
        >
          {children}
        </ProjectDestination.Remote.Queries.Entries.Loader>
      </SampleRequests.Actions.Initializer>
    </SampleRequests.Initializer>
  )
}

export const LinkToHistory = ({
  components: { Container = Styled.LinkToHistoryContainer } = {},
}) => (
  <Container>
    <ProjectLinks.ToRequestHistory>
      <Literals.LinkToHistory />
    </ProjectLinks.ToRequestHistory>
  </Container>
)
