import React from 'react'

import * as SampleRequest from '@arch-log/webapp.modules/project.sample_request/components/Reference'
import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import * as Icons from '@arch-log/webapp.shared/Icons'
import * as DefaultComponents from './DefaultComponents'
import * as Literals from './Literals'
import * as Fields from './Fields'

import * as Links from '@arch-log/webapp.shared/Links'

import * as styles from './styles.module.scss'

/**
 */
const TitleSection = ({
  components: {
    Container = DefaultComponents.TitleSectionContainer,
    ProductNameContainer = DefaultComponents.ProductNameContainer,
    ManufacturerContainer = DefaultComponents.ManufacturerContainer,
  } = {},
}) => (
  <Container>
    <ProductNameContainer>
      <Product.Properties.ProductName />
    </ProductNameContainer>
    <ManufacturerContainer>
      <Product.Properties.Identifier.Manufacturer.DisplayName />
    </ManufacturerContainer>
  </Container>
)

const LinkSection = ({
  components: {
    Container = DefaultComponents.LinkSectionContainer,
    Icon = DefaultComponents.LinkSectionIconContainer,
  } = {},
}) => {
  const {
    product: { id: product },
  } = React.useContext(Product.Context)

  return (
    <Container>
      <Links.ToProduct
        id={product}
        target="_blank"
        rel="noopener"
        className={styles.LinkButton}
      >
        <Icon>
          <Icons.Link />
        </Icon>
        <Literals.LinkToProduct />
      </Links.ToProduct>
    </Container>
  )
}

/**
 */
const FormSection = ({
  components: { Container = DefaultComponents.FormSectionContainer } = {},
}) => (
  <Container>
    <Fields.Shipments />
    <Fields.Note />
  </Container>
)

const Delete = ({
  components: { Button = DefaultComponents.DeleteButton } = {},
}) => {
  const { removeEntry } = React.useContext(SampleRequest.Actions.Context)

  return (
    <Button
      onDelete={() => {
        removeEntry()
      }}
    >
      <Icons.Close className={styles.DeleteIcon} />
    </Button>
  )
}

/**
 */
export const Default = ({
  components: {
    Container = DefaultComponents.Container,
    ImageWrapper = DefaultComponents.ImageWrapper,
    TitleAndFormWrapper = DefaultComponents.TitleAndFormWrapper,
  } = {},
}) => (
  <Initializer>
    <Container>
      <Delete />
      <ImageWrapper>
        <Product.Properties.Images.First>
          <Product.Properties.Image.Thumbnail className={styles.Image} />
        </Product.Properties.Images.First>
      </ImageWrapper>
      <TitleAndFormWrapper>
        <TitleSection />
        <LinkSection />
        <FormSection />
      </TitleAndFormWrapper>
    </Container>
  </Initializer>
)

const Initializer = ({ children }) => (
  <SampleRequest.Loaded>
    <SampleRequest.Actions.Initializer>
      {children}
    </SampleRequest.Actions.Initializer>
  </SampleRequest.Loaded>
)
