import React from 'react'

import { createFlow } from '@arch-log/webapp.atomics/components/Flow'

import * as Containers from './RequestStep/Containers'
import * as CompleteStep from './CompleteStep'
import * as RequestStep from './RequestStep'

/**
 */
export const BaseFlow = createFlow([RequestStep.Default, CompleteStep.Default])

/**
 */
export const Flow = ({ ...props }) => (
  <Containers.Initializer>
    <BaseFlow {...props} />
  </Containers.Initializer>
)
