import React from 'react'

import * as ProjectDestination from '@arch-log/webapp.modules/project.destination.v2/components'
import * as DefaultComponents from './DefaultComponents'

export const CompanyName = ({
  components: { Container = DefaultComponents.AddressComponentContainer } = {},
}) => (
  <Container>
    <ProjectDestination.Scope.Entry.Properties.ToCompany.CompanyName />
  </Container>
)

export const OperatorName = ({
  components: { Container = DefaultComponents.AddressComponentContainer } = {},
}) => (
  <Container>
    <ProjectDestination.Scope.Entry.Properties.ToCompany.OperatorLastName />
    <ProjectDestination.Scope.Entry.Properties.ToCompany.OperatorFirstName />
  </Container>
)

export const PostalCode = ({
  components: { Container = DefaultComponents.AddressComponentContainer } = {},
}) => (
  <Container>
    <ProjectDestination.Scope.Entry.Properties.Address.PostalCode />
  </Container>
)

export const Full = ({
  components: { Container = DefaultComponents.AddressComponentContainer } = {},
}) => (
  <Container>
    <ProjectDestination.Scope.Entry.Properties.Address.StateOrPrefecture />
    <ProjectDestination.Scope.Entry.Properties.Address.City />
    <ProjectDestination.Scope.Entry.Properties.Address.Street />
    <ProjectDestination.Scope.Entry.Properties.Address.Building />
  </Container>
)
