// extracted by mini-css-extract-plugin
export var Address = "styles-module--Address--dvMkW";
export var AddressComponent = "styles-module--AddressComponent--AaSA0";
export var CheckBox = "styles-module--CheckBox--DWtmq";
export var Container = "styles-module--Container--JI9hT";
export var DeleteButton = "styles-module--DeleteButton--ZM-RH";
export var DeleteIcon = "styles-module--DeleteIcon--6pDWi";
export var Image = "styles-module--Image--hZZvW";
export var LinkButton = "styles-module--LinkButton--effXW";
export var LinkSectionContainer = "styles-module--LinkSectionContainer--Bd9iH";
export var LinkSectionIconContainer = "styles-module--LinkSectionIconContainer--tucxt";
export var ManufacturerContainer = "styles-module--ManufacturerContainer--Bv9Wg";
export var ProductNameContainer = "styles-module--ProductNameContainer--5BBw7";
export var SelectContainer = "styles-module--SelectContainer--pmfkR";
export var SelectContainer_Deactive = "styles-module--SelectContainer_Deactive--3Wd06";
export var ShipmentAddresItemContainer = "styles-module--ShipmentAddresItemContainer--blufF";
export var ShipmentAddressContainer = "styles-module--ShipmentAddressContainer--kNfPQ";
export var ShipmentContainer = "styles-module--ShipmentContainer--eiGRw";
export var ShipmentQuantityContainer = "styles-module--ShipmentQuantityContainer--qXNdd";
export var ShipmentsContainer = "styles-module--ShipmentsContainer--I6rjI";
export var TextArea = "styles-module--TextArea--bctWS";
export var TitleAndFormWrapper = "styles-module--TitleAndFormWrapper--Fyvx1";
export var TitleSectionContainer = "styles-module--TitleSectionContainer--d98+n";