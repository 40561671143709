import React from 'react'

import * as Layout from './Layout'
import * as Containers from './Containers'

/**
 */
export const Default = ({
  components: {
    Message: messageComponents,
    Complete: completeComponents,
    Layout: {
      Container = Layout.Container,
      Body = Layout.Body,
      Footer = Layout.Footer,
    } = {},
  } = {},
  prev,
}) => {
  return (
    <Container>
      <Body>
        <Containers.Message components={messageComponents} />
      </Body>
      <Footer>
        <Containers.Complete
          onComplete={() => prev()}
          components={completeComponents}
        />
      </Footer>
    </Container>
  )
}
