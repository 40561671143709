import React from 'react'

import { Context } from './Context'
import * as ProductOrder from '@arch-log/webapp.modules/project.product/order'

export const Initializer = ({ children }) => {
  const [orderCondition, setOrderCondition] = React.useState({
    sortKey: ProductOrder.OrderBy.RegisteredAt,
    direction: ProductOrder.Direction.Desc,
  })

  return (
    <Context.Provider value={{ orderCondition, setOrderCondition }}>
      {children}
    </Context.Provider>
  )
}
